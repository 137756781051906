@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html,
body,
#root,
.app{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #d0e6f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0d47a1;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
    background: #d0e6f8;
}
