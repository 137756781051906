/* Background styles */
.gridBackground {
    background-color: #1c3340;
    background-image:
        linear-gradient(#325067cc 1px, transparent 1px),
        linear-gradient(90deg, #325067cc 1px, transparent 1px);
    background-size: 15px 15px;
}

.logoHover:hover {
    filter: brightness(120%);
    cursor: pointer;
}